<template>

<!--机构端 校园筛查 筛查计划管理 筛查计划查询  新增-->
  <div class="page">
    <div class="form-main">
      <el-form label-width="140px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini" class="account-ruleForm el-form">
        <el-form-item label="筛查计划名称" prop="screeningName">
          <el-input  v-model="ruleForm.screeningName" maxlength="30" placeholder="请填写计划名称"></el-input>
        </el-form-item>
        <el-form-item v-if="roleType === 9" label="学校" prop="greadSchoolClassSelect">
          <el-cascader :props="props" v-model="ruleForm.greadSchoolClassSelect" collapse-tags></el-cascader>
        </el-form-item>
        <el-form-item v-if="roleType === 10" label="学校班级年级" prop="greadSchoolClassSelect">
          <el-cascader :props="getGradeClass" v-model="ruleForm.greadSchoolClassSelect" collapse-tags></el-cascader>
        </el-form-item>
        <el-form-item label="筛查员" prop="sysList">
          <el-select
          multiple
          collapse-tags
          filterable
          v-model="ruleForm.sysList"
          clearable
          placeholder="请选择筛查员">
            <el-option
              v-for="item in teacherListData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.phone }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间" prop="newDate">
          <el-date-picker
            v-model="ruleForm.newDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="roleType === 10" label="是否上传机构" prop="uploading">
          <el-radio-group v-model="ruleForm.uploading">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
          <el-tag size="mini" style="margin-left: 30px" @click="messageAlert">?</el-tag>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" maxlength="100" resize="none" rows="3" v-model="ruleForm.remark" placeholder="请填写备注信息"></el-input>
        </el-form-item>
        <FormButtonGroup @saveData="submitForm('ruleForm')" save-text="保存"></FormButtonGroup>

      </el-form>

    </div>
  </div>
</template>

<script>
import screeningService from '@/globals/service/detection/screening.js'
// import schoolService from '@/globals/service/detection/school.js'
import selectService from '@/globals/service/detection/select.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
import storage from '@/globals/storage/index.js'
import SysService from '@/globals/service/detection/Sys.js'

export default {

  data () {
    return {
      // // 学校获取班级
      // getGradeClass: {
      //   lazy: true,
      //   checkStrictly: true,
      //   multiple: true,
      //   lazyLoad: (node, resolve) => {
      //     const { level } = node
      //     let data
      //     const schoolId = storage.get('schoolId')
      //     setTimeout(() => {
      //       if (level === 1) {
      //         selectService.class({ gradeId: node.value }).then(res => {
      //           data = res.data.map(item => {
      //             return {
      //               value: item.id,
      //               label: item.className,
      //               leaf: true
      //             }
      //           })
      //           if (data.length === 0) {
      //             // this.props.checkStrictly = true
      //             resolve(false)
      //             return
      //           }
      //           resolve(data)
      //         })
      //         return
      //       }
      //       selectService.grade({ schoolId: schoolId }).then(async res => {
      //         data = res.data.map(item => {
      //           return {
      //             value: item.id,
      //             label: item.gradeName,
      //             leaf: false
      //           }
      //         })
      //         if (data.length === 0) {
      //           // this.props.checkStrictly = true
      //           resolve([])
      //           return
      //         }
      //         return resolve(data)
      //       })
      //     }, 1000)
      //   }
      // },
      // 机构获取学校
      props: {
        lazy: true,
        checkStrictly: true,
        multiple: true,
        lazyLoad: (node, resolve) => {
          const { level } = node
          let data
          setTimeout(() => {
            if (level === 2) {
              selectService.class({ gradeId: node.value }).then(res => {
                // console.log('res1', res)
                data = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.className,
                    leaf: true
                  }
                })
                if (data.length === 0) {
                  this.props.checkStrictly = true
                  resolve(false)
                  return
                }
                resolve(data)
              })
              return
            }
            if (level === 1) {
              this.props.checkStrictly = true
              selectService.grade({ schoolId: node.value + '' }).then(async res => {
                // console.log('res2', res)
                data = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.gradeName,
                    leaf: false
                  }
                })
                if (data.length === 0) {
                  this.props.checkStrictly = true
                  resolve([])
                  return
                }
                return resolve(data)
              })
              return
            }
            selectService.school({ startPage: 1, pageSize: 1000 }).then(res => {
              // console.log('res3', res)
              const schoolId = storage.get('schoolId')
              if (schoolId) {
                data = res.data.schoolList.filter(item => item.id === schoolId)
              } else {
                data = res.data.schoolList
              }
              data = data.map(item => {
                return {
                  value: item.id,
                  label: item.schoolName,
                  leaf: false
                }
              })
              // console.log('data', data)
              return resolve(data)
            })
          }, 1000)
        }
      },
      // 学校获取班级
      getGradeClass: {
        lazy: true,
        checkStrictly: true,
        multiple: true,
        // disabled: false,
        // emitPath: false,
        lazyLoad: (node, resolve) => {
          const { level } = node
          let data
          // const schoolId = storage.get('schoolId')
          // setTimeout(() => {
          if (level === 2) {
            selectService.class({ gradeId: node.value }).then(res => {
              // console.log('res4', res)
              data = res.data.map(item => {
                return {
                  value: item.id,
                  label: item.className,
                  leaf: true
                }
              })
              if (data.length === 0) {
                // this.props.checkStrictly = true
                resolve(false)
                return
              }
              resolve(data)
            })
            return
          }

          if (level === 1) {
            this.props.checkStrictly = true
            // selectService.grade({ schoolId: schoolId }).then(async res => {
            selectService.grade({ schoolId: node.value + '' }).then(async res => {
              // console.log('res5', res)
              data = res.data.map(item => {
                return {
                  value: item.id,
                  label: item.gradeName,
                  leaf: false
                }
              })
              if (data.length === 0) {
                this.props.checkStrictly = true
                resolve([])
                return
              }
              // console.log('data', data)
              return resolve(data)
            })
            return
          }
          // }, 1000)
          selectService.school({ startPage: 1, pageSize: 1000 }).then(res => {
            // console.log('res7', res)
            // const schoolId = storage.get('schoolId')
            // if (schoolId) {
            //   // data = res.data.schoolList.filter(item => item.id === schoolId)
            //   data = res.data.schoolList
            // } else {
            //   data = res.data.schoolList
            // }
            data = res.data.schoolList.map(item => {
              return {
                value: item.id,
                label: item.schoolName,
                leaf: false
              }
            })
            return resolve(data)
          })
        }
      },
      // props: { multiple: true },
      schoolGreadClass: [],
      teacherListData: [],
      fullscreenLoading: false,
      startPage: 1,
      pageSize: 100,
      // schoolOptions: [],
      roleType: null,
      ruleForm: {
        screeningName: '',
        remark: '',
        greadSchoolClassSelect: null,
        // 筛查员Id
        sysList: [],
        // 日期
        newDate: [],
        // 是否上传机构
        uploading: 0
      },
      rules: {
        screeningName: [
          { required: true, message: '请填写计划名称', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 1 - 30 个字符内', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请填写备注信息', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 100 个字符内', trigger: 'blur' }
        ],
        greadSchoolClassSelect: [
          { required: true, message: '请选择学校年级班级', trigger: 'change' }
        ],
        uploading: [
          { required: true, message: '请选择是否上传机构', trigger: 'change' }
        ],
        sysList: [
          { required: true, message: '请选择筛查员', trigger: 'change' }
        ],
        newDate: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.getTeacher()
  },
  mounted () {
    this.roleType = Number(storage.get('roleType')) || null
  },
  methods: {
    messageAlert () {
      this.$notify({
        title: '提示',
        message: '选择上报后将筛查计划和筛查数据同步到合作机构',
        duration: 10000
      })
    },
    submitForm (formName) {
      // console.log('valid', this.$refs[formName].validate)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true
          const ruleForm = this.ruleForm
          ruleForm.schoolIdList = []
          ruleForm.gradeId = []
          ruleForm.classId = []
          const selectList = ruleForm.greadSchoolClassSelect.filter(item => item.length === 1)
          if (this.roleType === 9) {
            ruleForm.greadSchoolClassSelect.forEach(item => {
              if (item.length === 1) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(null)
                ruleForm.classId.push(null)
                return
              }
              if (selectList.some(data => data[0] === item[0])) return
              if (item.length === 2) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(null)
                return
              }
              if (item.length === 3) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(item[2])
              }
            }
            )
          }
          if (this.roleType === 10) {
            // const schoolId = storage.get('schoolId')
            // ruleForm.greadSchoolClassSelect.forEach(item => {
            //   if (item.length === 1) {
            //     ruleForm.schoolIdList.push(schoolId)
            //     ruleForm.gradeId.push(item[0])
            //     ruleForm.classId.push(null)
            //     return
            //   }
            //   if (selectList.some(data => data[0] === item[0])) return
            //   if (item.length === 2) {
            //     ruleForm.schoolIdList.push(schoolId)
            //     ruleForm.gradeId.push(item[0])
            //     ruleForm.classId.push(item[1])
            //   }
            // }
            // )
            ruleForm.greadSchoolClassSelect.forEach(item => {
              // console.log('item2', item)
              if (item.length === 1) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(null)
                ruleForm.classId.push(null)
                return
              }
              if (selectList.some(data => data[0] === item[0])) return
              if (item.length === 2) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(null)
                return
              }
              if (item.length === 3) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(item[2])
              }
            }
            )
          }

          ruleForm.startTime = new Date(ruleForm.newDate[0]).getTime()
          ruleForm.overTime = new Date(ruleForm.newDate[1]).getTime()
          screeningService.add(ruleForm).then(res => {
            this.$message({
              type: 'success',
              message: '添加成功!'
            })
            this.$router.push({ name: 'detectionScreeningPlanList' })
            this.fullscreenLoading = false
          })
        }
      })
    },
    getTeacher () {
      const schoolId = storage.get('schoolId')
      if (schoolId) {
        AdministratorService.AdministratorGetTeacherList({ schoolId: schoolId, pageSize: 50000, startPage: 1 }).then(res => {
          // console.log('res3', res)
          this.teacherListData = res.data.teacherList.map(item => {
            return {
              label: item.teacherName,
              value: item.teacherId,
              phone: item.phone

            }
          })
        })
      } else {
        SysService.search({ roleType: 11 }).then(res => {
          this.teacherListData = res.data.map(item => {
            return {
              label: item.sysName !== null ? item.sysName : '筛查员',
              value: item.id,
              phone: item.phone
            }
          })
        })
      }
    },
    getClassSelect () {
      this.ruleForm.classId = null
      this.classOptions = []
      selectService.class({ gradeId: this.ruleForm.gradeId + '' }).then(res => {
        const classList = res.data
        const classOptions = []
        classList.forEach(item => {
          classOptions.push({
            value: item.id,
            label: item.className
          })
        })
        this.classOptions = classOptions
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #333;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
  .form-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 0;
    box-sizing: border-box;
    max-width: 600px;
  }
  .page{
    padding: 20px 10px;
    background-color: #fff;
    min-height: 100%;
  }
  .red{
    color: #f50e0e;
  }
  .from-foot{
    margin-left: 30px;
    .el-button{
      margin-right: 10px;
    }
  }
</style>
